const envSettings = window;

export const appSettings = {
  apiUrl: envSettings.API_URL,
  jwtAuthority: envSettings.JWT_AUTHORITY,
  redirectUri: envSettings.REDIRECT_URL,
  ssoClientId: envSettings.SSO_CLIENT_ID,
  mobbyUrl: envSettings.MOBBY_URL,
  mobbyKDUrl: envSettings.MOBBYKD_URL,
}

export const isDev = process.env.NODE_ENV === "development";