import { removeItem, getItem } from "../../utils/storageUtils";
import { appSettings, isDev } from "../../utils/envUtils";

export const Mobby = () => {

  const code = getItem("auth");
  const _handleLogout = () => {
    removeItem("auth");
    window.location.href = "/";
  }

  window.addEventListener("message", (event) => {

    let origin = event.origin;
    if (origin === appSettings.mobbyUrl || isDev) {
      let data = typeof(event.data) === "string" ? JSON.parse(event.data) : event.data;

      if (data.type === "Authorize") {
        let messageId = data.messageId;

        //TODO: Get Mobby Token
        console.log({code})
        
        window.document.getElementById("mobby-hub").contentWindow.postMessage('{"messageId":"' + messageId + '","isSuccessfull":true,"args":[{"acessToken":' + code + '}]}', '*');
      }
    }
  })


  return (
    <>
      <iframe src={appSettings.mobbyKDUrl} title="MobbyHub" id="mobby-hub"   height="100%" width="100%"></iframe>
    </>
  )
}
