import React from 'react'
import styled from 'styled-components'

import icon from "../assets/img/ico-alert-driver-uber.svg"

export const Error = () => {
  return (
    <ERROR>
      <div className="header"></div>
      <div className="content">
        <img src={icon} alt="" />
        <h1>Não foi possível acessar as promoções</h1>
        <p>Parece que você não está ativo no app da Uber. Para ter acesso à essa parceria, o motorista parceiro precisa estar ativo e ter pelo menos 1 viagem realizada nos últimos 90 dias.</p>
      </div>
    </ERROR>
  )
}

const ERROR = styled.section`
  font-family: 'msh', sans-serif;
  .header {
    width: 100%;
    background-color: black;
    height: 50px;
  }
  .content {
    padding: 70px 40px;

    h1 {
      font-size: 24px;
    }
    p {
      font-size: 14px;
      margin: 20px 0px;
    }
  }
`

