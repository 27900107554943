import { Routes } from './routes';
import 'react-toastify/dist/ReactToastify.css';
import { createContext, useState } from 'react';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD1za11IT7_wpziAu1qYnMgmNi27ZH-5Ig",
  authDomain: "mobby-uber-voucher-seller.firebaseapp.com",
  projectId: "mobby-uber-voucher-seller",
  storageBucket: "mobby-uber-voucher-seller.appspot.com",
  messagingSenderId: "400826774281",
  appId: "1:400826774281:web:f1455fe21fbd5bc6e099d8",
  measurementId: "G-YBK8EQ3EW1"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

export const StoreContext = createContext();

function App() {
  const [store, setStore] = useState(
    {
      notifications: []
    }
  )


  return (
    <div className="app">
        <StoreContext.Provider value={{ store, setStore }}>
          <Routes />
        </StoreContext.Provider>
    </div>
  );
}

export default App;
