import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";
import { getItem } from "../utils/storageUtils";
import { Auth } from "../pages/Auth";
import { Error } from "../pages/Error";
import { ErrorCompliance } from "../pages/ErrorCompliance";
import { beginAuth } from "../services/authService";
import { ToastContainer } from 'react-toastify';
import { Mobby } from "../pages/Mobby/Index";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
      border: 0;
      font-family: 'msh', sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: 400;
    }
`

const PrivateRoute = ({ ...props }) => {
  const isAuthed = getItem("auth") ? true : false;
  const location = useLocation();

  return (
    isAuthed
      ?
      <main>
        <Route {...props} />
      </main>

      : <>{beginAuth(location)}</>
  )
}

const PublicRoute = ({ ...props }) => {
  const isAuthed = getItem("auth") ? true : false;

  return !isAuthed
    ? <Route {...props} />
    : <Redirect to="/mobby" />
}

export const Routes = () => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <PrivateRoute exact path="/" children={<Auth />} />
          <PrivateRoute exact path="/Mobby" children={<Mobby />} />
          <PublicRoute exact path="/Auth" children={<Auth />} />
          <PublicRoute exact path="/Error" children={<Error />} />
          <PublicRoute exact path="/ErrorCompliance" children={<ErrorCompliance />} />
          <Route exact path="*" children={<h1>página não encontrada</h1>} />

        </Switch>
      </BrowserRouter>
      <ToastContainer limit={1} />
    </>
  )
}
