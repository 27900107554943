import axios from "axios";
import { appSettings, isDev } from "../utils/envUtils";
import { getItem } from "../utils/storageUtils";

const api = axios.create({
  baseURL: appSettings.apiUrl,
});

const log = (message) => {
  if (isDev) console.debug(message)
}

api.interceptors.request.use(async config => {
  log(`Request tipo [${config.method.toUpperCase()}] na rota [${config.url}]`);

  const user = getItem("auth");
  // const { accessToken } = user;

  if (user) {
    config.headers.Authorization = `Bearer ${user}`;
  }
  return config;
});


// api.interceptors.response.use(
//   res => {
//   //   const { config: { method, url }, status, data, data: { error, success } } = res;

//   //   if (!success && !/menus/.test(url) && !/export/.test(url) && !/download/.test(url)) {
//   //     notify("error", error?.message);
//   //     throw error;
//   //   }

//   //   if (!data) {
//   //     notify("error", "A API retornou null");
//   //     throw error;
//   //   }

//   //   log(`SUCESSO: [${method.toUpperCase()}] na rota [${url}] status [${status}]`);


//   //   return data;
//   // },
//   // err => {
//   //   if (err?.response?.status === 401) {
//   //     const params = err.config.url.indexOf("?") !== -1 ? err.config.url.substring(err.config.url.indexOf("?")) : null;
//   //     notify("error", "Não autorizado, faça o login novamente!");

//   //     if (params) setItem(LAST_PARAMS, params);

//   //     removeItem(AUTHED_USER);
//   //     return setTimeout(() => {
//   //       window.location.reload()
//   //     }, 1000);
//   //   }

//   //   log(`ERRO: [${err?.response?.data?.error?.message || "API não retornou mensagem de erro"}] na rota [${err.config.url}]`);

//   //   notify("error", `ERRO: ${err?.response?.data?.error?.message || "API não retornou mensagem de erro"}`);
//   //   throw err;
//   // }
// );

export default api;