import styled, { keyframes } from "styled-components"
import { useLocation } from "react-router-dom"
import { useEffect } from "react"
import { setItem } from "../utils/storageUtils"
import { useState } from "react"
import { postAuthorizathion } from "../services/authService"

export const Auth = () => {
  const location = useLocation();
  const { search, hash } = location;
  const response = `${search}${hash}`;
  const [errorMessage, setErrorMessage] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      const position = null;
      navigator.geolocation.getCurrentPosition(showPosition, error);
    }
  }, [latitude, longitude])

  const showPosition = position => {
    setLatitude(position.coords.latitude)
    setLongitude(position.coords.longitude)
  }

  function error(err) {
    setLatitude(-23.550307301372342)
    setLongitude(-46.633865873025066)
  };

  useEffect(() => {
    if (latitude && longitude) {
      if (response.startsWith("?code=")) {
        let code = new URLSearchParams(response).get('code')
        // setItem("auth", code);
        postAuthorizathion({ authorizationCode: code, latitude: latitude, longitude: longitude }).then(res => {
          setItem("auth", res.data.accessToken);
          window.location = "/mobby";
        }).catch(res => {
          console.log(res.response.data.code);
          if (res.response.data.code === 203) {
            window.location = "/ErrorCompliance";
          } else {
            window.location = "/Error";
          }
        })
      }
      else {
        setErrorMessage(response.replace("?error=", ""));
      }
    }

  }, [response, latitude, longitude])

  return (
    <AUTH>
      <div className="auth__content">
        <strong>{errorMessage ?? "Autenticando..."} </strong>

      </div>
    </AUTH>
  )
}

const pulse = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
`;

const AUTH = styled.section`
  .auth {
    &__content {
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      > img {
        animation: ${pulse} 2000ms ease-in-out infinite;
        margin-bottom: 16px;
      }

    }
  }
`
