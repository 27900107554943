import { appSettings } from "../utils/envUtils";
import { stringify } from "query-string";
import api from "./api";

const response_type = "code";
const scope = "partner.accounts+partner.trips+partner.vehicles+vehicle_suppliers.partners.compliance+vehicle_suppliers.vehicles.read";

export const beginAuth = () => {

  const client_id = appSettings.ssoClientId;
  const authority = appSettings.jwtAuthority;
  const redirect_uri = appSettings.redirectUri;

  const response_url = `${redirect_uri}`;
  const params = stringify({
    client_id,
    response_type,
    redirect_uri: response_url,
  });

  const authUrl = `${authority}?${params}&${scope}`;
  console.log(authUrl)
  window.location.assign(authUrl);
};

export const postAuthorizathion = data => api.post(`/Authorization`, data);